import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"

import { ResponsivePie } from "@nivo/pie"
import { PageProps, graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SfiggyCard, { Sfiggy } from "../components/sfiggy"

type DataProps = {
  rarityJson: {
    layer: string
    rarity: [
      {
        label: string
        occurrence: number
        trait: string
      }
    ]
  }
  allMetadataJson: {
    nodes: [Sfiggy]
    totalCount: number
  }
}

type ContextType = {
  trait: string
}

const pieColors = [
  "hsl(90, 70%, 50%)",
  "hsl(56, 70%, 50%)",
  "hsl(103, 70%, 50%)",
  "hsl(186, 70%, 50%)",
  "hsl(104, 70%, 50%)",
]

const CollectionStatsTemplate: React.FC<PageProps<DataProps, ContextType>> = ({
  data,
  pageContext,
}) => {
  return (
    <Layout>
      <Seo title={data.rarityJson.layer} />
      <h1>
        {data.rarityJson.layer} / {pageContext.trait}
      </h1>
      <Container style={{ height: 450 }}>
        <ResponsivePie
          data={data.rarityJson.rarity.map((value, index) => {
            return {
              id: value.trait,
              label: value.trait,
              value: value.occurrence,
              color: pieColors[index],
            }
          })}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          onClick={trait => {
            navigate(
              `/collection/property/${data.rarityJson.layer.toLowerCase()}/filter/${trait.data.label.toLowerCase()}/`
            )
          }}
        />
      </Container>
      <Container>
        <Row>
          {data.allMetadataJson.nodes.map((art, index) => {
            return (
              <Col key={art.dna}>
                <SfiggyCard sfiggy={art} />
              </Col>
            )
          })}
        </Row>
      </Container>
    </Layout>
  )
}

export default CollectionStatsTemplate

export const query = graphql`
  query ArtProperties($layerID: String!, $trait: String!) {
    allMetadataJson(
      filter: { properties: { elemMatch: { value: { eq: $trait } } } }
      limit: 8
    ) {
      totalCount
      nodes {
        ...Sfiggy
      }
    }
    rarityJson(id: { eq: $layerID }) {
      layer
      rarity {
        label
        occurrence
        trait
      }
    }
  }
`
